import NextActionHandler from '../../v1/flex/auth/NextActionHandler'
import { convertApiError } from '../utils/utils'
import BlackbirdApi from './blackbirdApi'
import config from '../../../config'

export default class BlackbirdHandler {
  constructor(publicKey) {
    this.publicKey = publicKey
    this.blackbirdApi = new BlackbirdApi(this.publicKey)
  }

  getAccessTokenForPayment = ({ paymentId, merchantId, idempotencyKey }) => {
    this.paymentId = paymentId
    return this.blackbirdApi
      .getAccessToken({ paymentId, merchantId, idempotencyKey })
      .then((response) => {
        return { accessToken: response.accessToken, paymentId: paymentId }
      })
  }

  completePaymentAndProcessNextAction = ({
    paymentId,
    body,
    merchantId,
    idempotencyKey,
    embeddedContainer,
  }) => {
    const { mode } = body
    const stringifiedBody = JSON.stringify(body)

    return this.blackbirdApi
      .completePaymentRequest({
        paymentId,
        body: stringifiedBody,
        merchantId,
        idempotencyKey,
      })
      .then((completePaymentResult) =>
        NextActionHandler.maybeRedirect(
          { ...completePaymentResult, mode },
          embeddedContainer
        )
      )
      .then((completePaymentResult) => ({
        mode,
        id: completePaymentResult.id,
        status: completePaymentResult.status,
        source: completePaymentResult.paymentMethodDetails,
        paymentMethod: completePaymentResult.paymentMethod,
        ...convertApiError(completePaymentResult.error),
      }))
  }

  completePayment = ({
    paymentId,
    body,
    merchantId,
    idempotencyKey,
    proxy = false,
  }) => {
    const { mode } = body
    const stringifiedBody = JSON.stringify(body)

    const proxy_url =
      proxy === true
        ? `https://${config.vgs.IDENTIFIER}-${config.vgs.GOOGLE_PAY_ROUTE_ID}.${config.vgs.ENVIRONMENT}.verygoodproxy.com`
        : undefined

    return this.blackbirdApi
      .completePaymentRequest({
        paymentId,
        body: stringifiedBody,
        merchantId,
        idempotencyKey,
        base_url: proxy_url,
      })
      .then((completePaymentResult) => ({
        mode,
        id: completePaymentResult.id,
        status: completePaymentResult.status,
        bin: completePaymentResult?.nextAction?.deviceFingerprint?.bin,
        paymentMethod: completePaymentResult.paymentMethod,
        ...convertApiError(completePaymentResult.error),
        applePaySession: completePaymentResult?.nextAction?.applePaySession,
      }))
  }

  completeTestPayment = ({
    paymentId,
    body,
    merchantId,
    idempotencyKey
  }) => {
    const {mode} = body
    const stringifiedBody = JSON.stringify(body)

    return this.blackbirdApi
        .completeTestPaymentRequest({
          paymentId,
          body: stringifiedBody,
          merchantId,
          idempotencyKey,
        })
        .then((completePaymentResult) => ({
          mode,
          id: completePaymentResult.id,
          status: completePaymentResult.status,
          bin: completePaymentResult?.nextAction?.deviceFingerprint?.bin,
          paymentMethod: completePaymentResult.paymentMethod,
          ...convertApiError(completePaymentResult.error),
          applePaySession: completePaymentResult?.nextAction?.applePaySession,
        }))
  }
}
